import React from "react";
import { FormAsteriskH4 } from "../../../styles/forms";

/**
 * @component Form sections required information header below the section title
 */
export default function AsteriskHeader(){
    return (
        <FormAsteriskH4> 
            All Fields Required
        </FormAsteriskH4>
    )
}