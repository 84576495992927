// Using only the bare min for form
// This updates as the form updates if they use more fields
export const formTemplate = {
    owner1_first_name: '',
    owner1_last_name: '',
    owner1_email: '',
    owner1_phone: '',
    owner1_address1: '',
    owner1_address2: '',
    owner1_city: '',
    owner1_state: '',
    owner1_zipcode: '',
    referred_by: '',
    emergency1_name: '',
    emergency1_phone: '',
    emergency1_relation: '',
    emergency1_permission: '',
    auth1_name: '',
    auth1_relation: '',
    auth1_phone: '',
    pet1_name: '',
    pet1_species: '',
    pet1_breed: '',
    pet1_color: '',
    pet1_sex: '',
    pet1_altered: '',
    pet1_weight: '',
    pet1_dob: '',
    pet1_destructive: '',
    pet1_destructive_explain: '',
    pet1_fence: '',
    pet1_fence_explain: '',
    pet1_guard: '',
    pet1_guard_explain: '',
    pet1_social: '',
    pet1_social_explain: '',
    pet1_kennel: '',
    pet1_kennel_explain: '',
    pet1_extra_behavior: '',
    pet1_vet: '',
    pet1_vet_phone: '',
    pet1_food_allergy: '',
    pet1_food_allergy_explain: '',
    pet1_medical_condition: '',
    pet1_medical_condition_explain: '',
    pet1_past_injury: '',
    pet1_past_inury_explain: '',
    pet1_extra_medical: '',
    liability_waiver_owner_acknowledgement: '',
    cancel_waiver_owner_acknowledgement: '',
    waiver_owner_name: '',
}