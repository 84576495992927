export const speciesOptions = [
    'Dog',
    'Cat',
    'Bunny',
    'Guinea Pig',
    'Hamster',
    'Bird',
    'Leopard Gecko',
    'Snake',
    'Other Reptile',
    'Other'
]